import Vue from 'vue'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

Vue.use(VTooltip, {
  defaultTrigger: 'hover focus touch'
})

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('v-popover', VPopover)
