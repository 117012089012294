import { GetterTree, ActionTree, MutationTree } from 'vuex'

export const state = () => ({
  isRefresh: false,
  isDisabledRefresh: false,
  isSideOpen: false
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  isRefresh: (state: RootState) => state.isRefresh,
  isDisabledRefresh: (state: RootState) => state.isDisabledRefresh,
  isSideOpen: (state: RootState) => state.isSideOpen
}

export const mutations: MutationTree<RootState> = {
  TOGGLE_REFRESH: (state: RootState, status?: boolean) => (state.isRefresh = (status !== undefined) ? status : !state.isRefresh),
  TOGGLE_DISABLE_REFRESH: (state: RootState, status?: boolean) => (state.isDisabledRefresh = (status !== undefined) ? status : !state.isDisabledRefresh),
  TOGGLE_SIDE: (state, status?: boolean) => (state.isSideOpen = (status !== undefined) ? status : !state.isSideOpen)
}

export const actions: ActionTree<RootState, RootState> = {
  toggleRefresh({ commit }, status?: boolean) {
    commit('TOGGLE_REFRESH', status)
  },
  toggleDisableRefresh({ commit }, status?: boolean) {
    commit('TOGGLE_DISABLE_REFRESH', status)
  },
  toggleSide({ commit }, status?: boolean) {
    commit('TOGGLE_SIDE', status)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
