import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { findIndex } from 'lodash'
import Vue from 'vue'
import Task from '~/interfaces/Task'
import FetchTasksRequest from '~/interfaces/requests/FetchTasksRequest'

export const state = () => ({
  future: <Task[]>[]
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  future: (state: RootState) => state.future
}

export const mutations: MutationTree<RootState> = {
  SET_FUTURE: (state: RootState, future?: []) =>
    (state.future = future !== undefined ? future : []),
  UPDATE_FUTURE: (state: RootState, task: Task) => {
    const index: number = findIndex(
      state.future,
      (item: Task) => item.uuid === task.uuid
    )
    Vue.set(state.future, index, task)
  }
}

export const actions: ActionTree<RootState, RootState> = {
  updateFuture({ commit }, task: Task) {
    commit('UPDATE_FUTURE', task)
  },
  // eslint-disable-next-line no-empty-pattern
  fetchTask({}, uuid: string) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('tasks/' + uuid)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchFutureTasks({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('tasks/future', {
          params: {
            locale: this.$i18n.locale,
          }
        })
        .then(response => {
          commit('SET_FUTURE', response.data.data)
          if (response.data.meta.user) {
            commit(
              'auth/SET',
              {
                key: 'user',
                value: response.data.meta.user
              },
              { root: true }
            )
          }
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  // eslint-disable-next-line no-empty-pattern
  fetchArchiveTasks({}, requestOptions: FetchTasksRequest) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('tasks/archive', {
          params: {
            'page[number]': requestOptions.page.number
              ? requestOptions.page.number
              : 1,
            'page[size]': requestOptions.page.size
              ? requestOptions.page.size
              : 20,
            from: requestOptions.from,
            to: requestOptions.to,
            locale: this.$i18n.locale,
          }
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  // eslint-disable-next-line no-empty-pattern
  addService({}, options: { uuid: string; key: string; value: number }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`tasks/${options.uuid}/additional-service`, {
          service: options.key,
          options: {
            count: options.value
          }
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  // eslint-disable-next-line no-empty-pattern
  removeService({}, options: { uuid: string; key: string }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(`tasks/${options.uuid}/additional-service`, {
          data: {
            service: options.key
          }
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  // eslint-disable-next-line no-empty-pattern
  updateService({}, options: { uuid: string; key: string; value: number }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(`tasks/${options.uuid}/additional-service`, {
          service: options.key,
          options: {
            count: options.value
          }
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
