import { GetterTree, ActionTree, MutationTree } from 'vuex'
import PaginationRequest from '~/interfaces/requests/PaginationRequest'

export const state = () => ({})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {}

export const mutations: MutationTree<RootState> = {

}

export const actions: ActionTree<RootState, RootState> = {
  fetchNotifications({ commit }, requestOptions: PaginationRequest) {
    return new Promise((resolve, reject) => {
      this.$axios.get('profile/notifications', {
        params: {
          'page[number]': requestOptions.page.number ? requestOptions.page.number : 1,
          'page[size]': requestOptions.page.size ? requestOptions.page.size : 20
        }
      })
        .then(response => {
          if (response.data.meta.user) {
            commit('auth/SET', {
              key: 'user',
              value: response.data.meta.user
            }, { root: true })
          }
          return resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  setNotificationsRead({ commit }, { notifications }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('profile/notifications/read', {
        notifications
      })
        .then(response => {
          if (response.data.meta.user) {
            commit('auth/SET', {
              key: 'user',
              value: response.data.meta.user
            }, { root: true })
          }
          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
