




































































































































































import { computed, defineComponent, SetupContext } from '@vue/composition-api'
import Data from '~/interfaces/Data'
import LanguageSwitcher from "~/components/tools/LanguageSwitcher.vue"

export default defineComponent({
  components: {
    LanguageSwitcher
  },
  metaInfo: {
    title: 'Tasks'
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(props: Data, context: SetupContext) {
    const user = computed(() => context.root.$store.getters.user)

    function logout() {
      context.root.$auth.logout()
        .then(() => {
          context.root.$store.dispatch('ui/toggleSide', false)
          context.root.$store.dispatch('ui/toggleRefresh', false)
          window.location.reload()
        })
    }

    return { user, logout }
  }
})
