import { Plugin, Context } from '@nuxt/types'

declare module '@nuxt/types' {
  interface Context {
    $generateFireMessToken(): void

    $sendFireMessageToken(token: string): void
  }
}

const fireNotifications: Plugin = (context: Context) => {
  if (!context.app.$fire || !context.app.$fire.messaging) {
    return
  }
  context.app.$fire.messaging.usePublicVapidKey(context.app.$config.firebaseAppPK)

  context.app.$fire.messaging.onTokenRefresh(() => {
    context.app.$fire.messaging.getToken().then((refreshedToken: string) => {
      localStorage.setItem('fireMessageToken', refreshedToken)
      localStorage.setItem('fireMessageUser', context.store.state.auth.user.uuid)
      context.$sendFireMessageToken(refreshedToken)
    }).catch(() => {
      localStorage.removeItem('fireMessageToken')
    })
  })

  context.app.$fire.messaging.onMessage((payload: any) => {
    // eslint-disable-next-line no-console
    // console.log('Message received. ', payload)

    let content = ''
    if (payload.notification.title) {
      content += '<div class="font-bold bock">' + payload.notification.title + '</div>'
    }
    if (payload.notification.body) {
      content += `<div>${payload.notification.body}</div>`
    }
    content = `<div class="flex"><pre>${content}</pre></div>`
    context.app.$toast.info(content, {
      duration: 999999000
    })
    context.store.dispatch('ui/toggleRefresh', true)
  })

  context.$generateFireMessToken = () => {
    if (!context.store.state.auth.user) {
      return
    }
    context.app.$fire.messaging.getToken().then((currentToken: string) => {
      if (currentToken) {
        localStorage.setItem('fireMessageToken', currentToken)
        localStorage.setItem('fireMessageUser', context.store.state.auth.user.uuid)
        context.$sendFireMessageToken(currentToken)
      } else {
        // Show permission request.
        // eslint-disable-next-line no-console
        console.log('No Instance ID token available. Request permission to generate one.')
        localStorage.removeItem('fireMessageToken')
        // Show permission UI.
      }
    }).catch(() => {
      context.app.$toast.error(context.app.i18n.t('errors.notificationsBlocked').toString())
    })
  }

  context.$sendFireMessageToken = (token: string) => {
    context.$axios.post('profile/notification-token', {
      token,
      type: 'web'
    }).then(() => {
      // eslint-disable-next-line no-console
      // console.log(`token sent: "${token}"`)
    }).catch(() => {
      context.app.$toast.error(context.app.i18n.t('errors.request_error').toString())
    })
  }
}

export default fireNotifications
