import Vue from 'vue';
import VueTailwind from 'vue-tailwind';

import {
  TRadio,
  TRadioGroup,
} from 'vue-tailwind/dist/components';

const components = {
  TRadio,
  TRadioGroup
};

// const settings = {
//   TRadioGroup: {
//     classes: {
//       groupWrapper: 'flex',
//       label: '',
//       input: 'text-green-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed',
//       inputWrapper: 'inline-flex',
//       wrapper: 'inline-flex items-center space-x-2',
//       // labelChecked: '',
//       // inputWrapperChecked: '',
//       // wrapperChecked: '',
//     }
//     // Variants and fixed classes in the same `object` format ...
//   },
// };

Vue.use(VueTailwind, components);
// Vue.use(VueTailwind, settings);
