var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-h-screen flex flex-col pt-12",class:{
    'state-refreshing': _vm.$store.getters['ui/isRefresh'],
    'state-side-open': _vm.$store.getters['ui/isSideOpen']
  }},[_c('header',{ref:"header",staticClass:"fixed z-40 top-0 left-0 w-full bg-white z-40 border-b border-gray-300 bg-blue-800 text-white"},[_c('div',{staticClass:"w-full p-4 flex flex-row"},[_c('div',[_c('a',{staticClass:"cursor-pointer sidebar-toggle",attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('ui/toggleSide')}}},[_c('i',{staticClass:"fas fa-bars"}),_vm._v(" "),_c('i',{staticClass:"fas fa-times"})])]),_vm._v(" "),_c('div',{staticClass:"flex-grow text-right"},[_c('language-switcher'),_vm._v(" "),_c('n-link',{attrs:{"to":_vm.localePath('/profile/notifications')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
return [_c('a',{staticClass:"relative duration-500 transition-opacity",class:{'opacity-50': _vm.user.unread_notifications <= 0},attrs:{"href":href},on:{"click":navigate}},[(_vm.user.unread_notifications > 0)?_c('span',{staticClass:"block transform -translate-y-1/2 translate-x-1/2 absolute right-0 top-0 bg-red-500 text-center text-white text-xs font-bold rounded-full px-1"},[_vm._v("\n              "+_vm._s(_vm.user.unread_notifications)+"\n            ")]):_vm._e(),_vm._v(" "),_c('i',{staticClass:"far fa-comment-lines"})])]}}])}),_vm._v(" "),_c('a',{staticClass:"cursor-pointer refresh-indicator ml-4",class:{'opacity-50': (_vm.$store.getters['ui/isSideOpen'] || _vm.$store.getters['ui/isDisabledRefresh'])},attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();!(_vm.$store.getters['ui/isSideOpen'] || _vm.$store.getters['ui/isDisabledRefresh'])?_vm.$store.dispatch('ui/toggleRefresh'):false}}},[_c('i',{staticClass:"fas fa-sync-alt"})])],1)])]),_vm._v(" "),_c('div',{staticClass:"flex-grow flex flex-row relative"},[_c('aside',{staticClass:"account-sidebar"},[_c('ul',[_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('/')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"account-sidebar__item",class:{active: isExactActive},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-home"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.home')))])])]}}])})],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('/tasks/archive')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"account-sidebar__item",class:{active: isExactActive},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-archive"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.archive')))])])]}}])})],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('/tasks/schedule')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"account-sidebar__item",class:{active: isExactActive},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-calendar-alt"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.schedule')))])])]}}])})],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('/stock')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"account-sidebar__item",class:{active: isExactActive},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-backpack"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.inventory')))])])]}}])})],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('/profile')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"account-sidebar__item",class:{active: isExactActive},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-user"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.profile')))])])]}}])})],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('/profile/password')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"account-sidebar__item",class:{active: isExactActive},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-lock"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.password')))])])]}}])})],1),_vm._v(" "),_c('li',[_c('n-link',{attrs:{"to":_vm.localePath('/documentation')},nativeOn:{"click":function($event){return _vm.$store.dispatch('ui/toggleSide', false)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var navigate = ref.navigate;
  var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"account-sidebar__item",class:{active: isExactActive},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"far fa-book-open"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.manual')))])])]}}])})],1),_vm._v(" "),_c('li',[_c('a',{staticClass:"account-sidebar__item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-door-open"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cleaner_portal.logout')))])])])])]),_vm._v(" "),_c('div',{staticClass:"flex-grow flex flex-col max-w-full"},[_c('div',{staticClass:"flex-grow"},[_c('nuxt')],1),_vm._v(" "),_c('footer',{staticClass:"text-center py-2 border-t border-gray-200 text-xs"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('cleaner_portal.company_copy'))}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }