export default function ({ app, $auth }) {
  $auth.onRedirect((to) => {
    let redirect = null

    switch (to) {
      case '/':
        redirect = app.localePath(to)
        break
      case '/auth/login':
        redirect = app.localePath(to)
        break
      default:
        redirect = to
        break
    }
    return redirect
  })
}
