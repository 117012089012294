// https://nuxt-community.github.io/nuxt-i18n/lazy-load-translations.html

export default {
  auth: {
    login: 'Телефон',
    password: 'Пароль',
    loginPage: 'Вхід в систему',
    loginButton: 'Вхід'
  },
  role: {
    cleaner_base: 'Клінер стандартних прибирань',
    cleaner_complex: 'Клінер комплексних прибирань',
    foreman: 'Бригадир'
  },
  rank: {
    junior: 'Молодший клінер',
    middle: 'Вправний клінер',
    senior: 'Старший клінер'

  },
  premises: {
    room1: '1-кім',
    room2: '2-кім',
    room3: '3-кім',
    room4: '4-кім',
    home: 'Дім',
    office: 'Офіс',
    per_hour: 'Погодинно',
    windows: 'Вікна'
  },
  types: {
    basic: 'Стандарна',
    maintain: 'Експрес',
    office: 'Офіс',
    per_hour: 'Погодинно',
    spring_cleaning: 'Генер.',
    after_repair: 'Після рем.',
    windows: 'Вікна',
    dry_cleaning: 'Хімчистка',
    after_the_fire: 'Після пожежі',
    sewerage: 'Каналізація',
    remake: 'Переприбирання',
    wfp: 'WFP',
  },
  statuses: {
    ordered: 'Замовлений',
    done: 'Виконаний',
    payed: 'Оплачений',
    declined: 'Скасований',
    open: 'Відкритий',
    close: 'Закритий',
    selected_other: 'Скасований'
  },
  date: {
    years: 'рік | років',
    months: 'місяць | місяців',
    days: 'день | днів',
    short_years: 'р.',
    short_months: 'міс.',
    short_days: 'дн.',
    day: 'День',
    week: 'Тиждень',
    mon: 'Пн',
    tue: 'Вт',
    wed: 'Ср',
    thu: 'Чт',
    fri: 'Пт',
    sat: 'Сб',
    sun: 'Нд',
  },
  region: {
    kyiv: 'Київ',
    dnepr: 'Дніпро',
    kharkov: 'Харків',
    lviv: 'Львів',
    prague: 'Прага',
    odessa: 'Одеса',
  },
  trackers: {
    order: 'Замовлення',
    estimate: 'Оцінка 💰',
    remake: 'Переприбирання',
    continue: 'Продовження'
  },
  services: {
    clean_refrigerator: 'У холодильнику (із прод.)',
    clean_refrigerator_full: 'У холодильнику',
    clean_oven: 'У духовці',
    range_hood: 'Витяжка',
    clean_microwave: 'У мікрохвильовій печі',
    clean_cabinet: 'Шафки',
    clean_wardrobe: 'Гардероб',
    clean_balcony: 'На балконі',
    clean_balcony_inner: 'На лоджії',
    clean_window: 'Вікна',
    clean_window_full: 'Вікна з прибиранням',
    clean_window_side: 'Вікна з одного боку',
    ironing: 'Прасування',
    washing_dishes: 'Миття посуду',
    per_hour: 'Погодинно',
    toilet: 'Санвузол (туалет)',
    additional_toilet: 'Санвузол',
    keys_delivery: 'Доставка ключів',
    washing: 'Прання',
    washing_walls: 'Мийка стін',
    clean_pet_box: 'Лоток домашніх тварин',
  },
  measure: {
    unit: 'шт.',
    kilogram: 'кг.',
    liter: 'літ.',
    meter: 'м.',
    packaging: 'уп.',
    box: 'ящ.'
  },
  cleaner_portal: {
    average_rating: 'Ваш рейтинг серед клієнтів',
    logout: 'Вихід',
    archive: 'Архів',
    schedule: 'Графік доступності',
    stock: 'Склад',
    inventory: 'Інвентар',
    home: 'Головна',
    profile: 'Профіль',
    password: 'Пароль',
    change_password: 'Змінити пароль',
    manual: 'Інструкція',
    future_tasks: 'Майбутні завдання',
    archive_tasks: 'Архів завдань',
    notifications: 'Повідомлення',
    avatar: 'Аватар',
    name: "Ім'я",
    email: 'Імеіл',
    phone: 'Телефон',
    phone2: 'Додатковий телефон',
    premises_type: 'Тип приміщення',
    rank: 'Ранг',
    carier_start_at: 'Початок роботи',
    role: 'Пасада',
    address: 'Адреса',
    region: 'Регіон',
    card_number: 'Банківська картка',
    uuid: 'Ідентифікатор',
    total_resolved_tasks: 'Загальна кількість виконаних завдань',
    features: 'Особливості',
    current_password: 'Поточний пароль',
    new_password: 'Новий пароль',
    repeat_new_password: 'Повторіть новий пароль',
    need_hoover: 'ВЗЯТИ ПИЛОСОС!',
    expired: '',
    order_status: 'Статус замовлення',
    order_type: 'Вид прибирання',
    additional_info: 'Додаткова інформація',
    additional_services: 'Додаткові сервіси',
    colleagues: 'Колеги',
    clean_memo: 'Технологічна карта',
    waiting_contact_ok: 'Очікуємо на підтвердження клієнтом замовлення',
    contact_ok: 'Клієнт підтвердив замовлення',
    note: 'Позначка',
    blogger: 'Блогер',
    subscription: 'Прибирання за підпискою',
    company_copy: '© "<a target="_blank" href="https://mee.services" class="hover:text-blue-500 underline">Mee.services</a>", 2019 - Теперішній час',
    click_viewed: 'Я ознайомлений',
    click_on_place: 'Я на місці',
    click_done: 'Прибирання виконано',
    button_loading: 'Оновлення',
    thanks: 'Дякую за роботу !',
    tracker: 'Категорія завдання',
    startAt: 'Початок о ',
    leftTime: 'Через',
    present: 'В наявності',
    salary: 'Зарплата',
    clarify: 'Зарплату уточнюйте у менеджера',
    checklist: 'Чек-лист прибирання',
    save_checklist: 'Зберегти чек-лист прибирання',
  },
  pagination: {
    from: 'з'
  },
  inputErrors: {
    empty: 'Поле не повинно бути порожнім'
  },
  actions: {
    save: 'Зберегти',
    cancel: 'Скасувати',
    change: 'Так',
    ok: 'ОК',
    services: 'Додати послугу'
  },
  alert: {
    note: '(Якщо над завданням працює кілька людей, статус оновиться у всіх.)',
    is_cleaner_ok: {
      title: 'Ознайомлення',
      text: 'Ознайомились із завданням?'
    },
    is_presence: {
      title: 'Готовність',
      text: 'Прибули за вказаною адресою?'
    },
    is_cleaner_done: {
      title: 'Перемога!',
      text: 'Завдання виконано 🥇'
    }
  },
  success: {
    saving: 'Збереження...',
    copied: 'Скопійовано в буфер обміну',
    updated: 'Дані оновлено',
    password_updated: 'Пароль оновлено'
  },
  errors: {
    notificationsBlocked: 'Нотифікації заблоковано. Будь ласка, розблокуйте нотифікації, щоб отримувати сповіщення.',
    login_error: 'Дані не співпадають із базою',
    address_error: 'Помилка в адресі!',
    data_not_found: 'Дані не знайдені',
    no_tasks: "Завдань у найближчі дні не знайдено. Але не засмучуйтесь, менеджери вже працюють над тим, щоб у вас з'явилися нові, цікаві завдання!",
    no_archive_tasks: 'В архіві не виявлено завдань. Можливо, варто змінити критерії пошуку і все знайдеться.',
    no_inventory: 'На даний момент немає записів про видачу інвентарю',
    no_notifications: 'Сповіщень не знайдено.',
    try_refresh: 'Спробуйте оновити сторінку',
    request_error: 'Сервер не відповідає. Спробуйте оновити сторінку',
    ask_manager: 'Ви не можете змінити дані у базі. Будь ласка зверніться до вашого менеджера.',
    copy_error: 'Під час копіювання сталася помилка ⚠',
    need_select_service: 'Будь ласка, виберіть послугу',
    right_format: 'Вірний формат',
    validation_error: 'Неправильний формат даних у запиті. Перевірте дані, що вводяться.',
    server_error: 'Помилка на сервері під час обробки запиту. Якщо помилка буде повторюватися, зверніться до служби підтримки.'
  },
  manual: {
    howUpdate: 'Як оновити дані на сторінці?',
    howUpdateText: 'Натиснути кнопку "оновлення" вгорі сторінки',
    howShowHelp: 'Як дізнатися, що означає інформаційна картинка?',
    howShowHelpText: "При натисканні на більшість інформаційних картинок з'являється пояснювальна фраза",
    howHideToast: "Як прибрати нотифікацію, що з'явилася",
    howHideToastText: 'Нотифікація пропадає сама за 10 секунд. Але якщо вам потрібно прибрати її раніше, то просто простягніть ліворуч або праворуч.',
    howChangeStatus: 'Як змінити статус завдання?',
    howChangeStatusText: 'Ви можете змінювати такі статуси як: "Я ознайомлений", "Я на місці", "Прибирання виконано". Якщо ви ознайомилися із завданням, то натискайте "Я ознайомлений". Коли прибули на місце і готові до роботи, клацніть "Я на місці". Коли робота виконана, то перед виходом не забудьте натиснути "Прибирання виконано", будь ласка.'
  },
  filters: {
    dateRage: 'Фільтр за датою'
  },
  schedule_statuses: {
    a: 'Доступний',
    v: 'Відпустка',
    l: 'Йде',
    m: 'Перенести день',
    i: 'Хвороба',
    n: 'Негатив',
    p: 'Зник',
    f: 'Вигнати',
    b: 'Зайнятий',
  },
}
