import { GetterTree, ActionTree, MutationTree } from 'vuex'

export const state = () => ({
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
}

export const mutations: MutationTree<RootState> = {
}

export const actions: ActionTree<RootState, RootState> = {
  // eslint-disable-next-line no-empty-pattern
  fetchStockInventory({}) {
    return new Promise((resolve, reject) => {
      this.$axios.get('stock/inventory', {
        params: {
        }
      })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  // eslint-disable-next-line no-empty-pattern
  updateInventoryBalance({}, { inventory, balance }) {
    return new Promise((resolve, reject) => {
      this.$axios.put('stock/inventory/' + inventory.id, {
        balance
      })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
