
























import { ref, defineComponent, SetupContext } from '@vue/composition-api'
import LanguageSwitcher from "~/components/tools/LanguageSwitcher.vue"

export default defineComponent({
  components: {
    LanguageSwitcher
  },
  metaInfo: {
    title: 'Tasks Application'
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(props: any, context: SetupContext) {
    const bgSources = [
      `/${context.root.$config.deployAppType}/images/auth_bg/auth_hero.jpg`,
      `/${context.root.$config.deployAppType}/images/auth_bg/auth_hero2.jpg`,
      `/${context.root.$config.deployAppType}/images/auth_bg/auth_hero3.jpg`,
      `/${context.root.$config.deployAppType}/images/auth_bg/auth_hero4.jpeg`
    ]
    const bgSource = ref(bgSources[Math.floor(Math.random() * bgSources.length)])

    return { bgSource }
  }
})
