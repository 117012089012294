<template>
  <div class="inline">
    <div class="switch-lang" :style="{ top: top, right: right }">
      <div class="current-lang" @click="toggle=!toggle">
        <img class="lang-flag" :src="imgSrc($root.$i18n.locale)" alt="" />
        <p class="lang-text">
          {{ $root.$i18n.locale }}
        </p>
      </div>

      <div class="lang-dropdown" :class="[toggle ? 'lang-dropdown-block' : 'lang-dropdown-none']">
        <div
          v-for="(language, index) in locales"
          :key="'lang-'+index"
          class="selecting-lang"
          @click="changeLang(language.code)"
        >
          <img class="lang-flag" :src="imgSrc(language.code)" alt="" />
          <p class="lang-text">
            {{ language.code }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  props: {
    top: {
      type: String,
      default: '15px'
    },
    right: {
      type: String,
      default: '90px'
    },
  },

  data() {
    return {
      toggle: false,
      locales: [],
    };
  },
  mounted() {
    const availableLocales = ['uk','en','ru'];

    this.$root.$i18n.locales.forEach((locale) => {
      if (availableLocales.includes(locale.code)) {
        this.locales.push(locale);
      }
    });
  },
  methods: {
    imgSrc(value) {
      return '/flags/' + value + '.png';
    },
    changeLang(value) {
      this.toggle = !this.toggle;
      this.$router.push(this.switchLocalePath(value));
    },
  }
};
</script>

<style scoped>

</style>
